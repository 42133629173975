<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" text v-on="on" class="xsmall" @click.stop="dialog = true">
        {{tournament.externalRegistrationUrl ? 'Edit' : 'Add'}} Tournament Point Multiplier
      </v-btn>
    </template>

    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Point Multiplier</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog=false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar v-model="loading"></loading-bar>
      <v-card-text>
        <v-select
          :items="[
            { text: 'Normal Points', value: 1 },
            { text: '1.5X Points', value: 1.5 },
            { text: '2X Points', value: 2 }
          ]"
          v-model="pm"
          label="Multiplier"
        ></v-select>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="success white--text"
          small fab
          @click.stop="savePM"
          :disabled="!dirty"
          :loading="loading"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
        <v-btn
          color="error white--text"
          small fab
          @click.stop="dialog=false"
          :disabled="loading"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      pm: null,
      tpm: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    dirty () {
      return this.pm !== this.tpm
    }
  },
  methods: {
    getPM () {
      this.loading = true
      this.$VBL.tournament.multiplier.get(this.tournament.id)
        .then(r => { this.pm = this.tpm = r.data })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    saveTest () {
      console.log(this.pm)
    },
    savePM () {
      this.loading = true
      console.log(this.pm)
      this.$VBL.tournament.multiplier.set(this.tournament.id, this.pm)
        .then(r => {
          this.tpm = this.pm
          this.dialog = false
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function (v) {
      v && this.getPM()
    }
  }
}
</script>
